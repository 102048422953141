import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "create-instrctor" }
const _hoisted_2 = { class: "create-instrctor__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "create-instrctor__images-container" }
const _hoisted_6 = { class: "create-instrctor__images-preview-wrap" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { class: "create-instrctor__photo-block" }
const _hoisted_12 = {
  key: 0,
  class: "error__logo"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "create-instrctor__selected-wrap"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = ["for"]
const _hoisted_20 = ["id", "name", "onChange", "checked"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "create-instrctor__selected-wrap"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = ["for"]
const _hoisted_27 = ["id", "name", "onChange", "checked"]
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "form-group" }
const _hoisted_30 = { class: "toggle-label" }
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { class: "create-instrctor__button" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/super-admin/school-list",
      class: "create-instrctor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Create school "))
        : _createCommentVNode("", true),
      (this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Edit school "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      onSubmit: _ctx.createSchool,
      class: "create-instrctor__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (!this.isPreview && this.schoolData.image === undefined)
              ? (_openBlock(), _createBlock(_component_PhotoMock, { key: 0 }))
              : _createCommentVNode("", true),
            (this.isPreview && this.schoolData.image === null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.imagePreview,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            (this.isPreview && this.schoolData.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.imagePreview,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            (
              this.schoolData.image !== null &&
              !this.isPreview &&
              this.schoolData.image !== undefined
            )
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: this.schoolData.image,
                  class: "create-instrctor__images-preview"
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true),
            (this.errorSchool)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_AlertBlock, {
                    message: this.errorSchool.image
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_11, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "create-instrctor__upload-photo first-button" }, " Upload ", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "file",
                ref: "file",
                accept: ".png, .jpg, .jpeg",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload()))
              }, null, 544),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "create-instrctor__upload-info" }, " Picture must be 300 px * 300 px and no more than 10 MB ", -1))
            ]),
            (this.errorSchool)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_AlertBlock, {
                    message: this.errorSchool.logo
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "name",
            class: "form-group-label"
          }, "School name ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "name",
            modelValue: this.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.name) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorSchool)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorSchool.name
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Choose school admin ", -1)),
          _createElementVNode("div", {
            class: "create-instrctor__multi-select-toggle",
            ref: "multiSelectToggleAdmin",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleActiveClass && _ctx.toggleActiveClass(...args)))
          }, [
            (this.adminNames.length === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Choose"))
              : (_openBlock(), _createElementBlock("span", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.adminNames, (name, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: name }, [
                      (index > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, ", "))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(name), 1)
                    ]))
                  }), 128))
                ])),
            _createVNode(_component_Arrow, {
              class: _normalizeClass({ rotateArrow: this.isActive })
            }, null, 8, ["class"])
          ], 512),
          _createElementVNode("div", {
            class: _normalizeClass(["create-instrctor__multi-select-wrap", { ActiveMultiSelect: this.isActive }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.dataAdmins, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "create-instrctor__multi-select"
              }, [
                _createElementVNode("label", {
                  for: item.id,
                  class: "custom-checkbox"
                }, [
                  _createElementVNode("input", {
                    id: item.id,
                    name: item.full_name,
                    onChange: ($event: any) => (_ctx.toggleInstructor(item.id, $event, item.full_name)),
                    checked: _ctx.isChecked(item.id),
                    type: "checkbox"
                  }, null, 40, _hoisted_20),
                  _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                ], 8, _hoisted_19)
              ]))
            }), 128))
          ], 2),
          (this.errorSchool)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorSchool.admin_ids
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Choose financial manager ", -1)),
          _createElementVNode("div", {
            class: "create-instrctor__multi-select-toggle",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleActiveFinancialManager && _ctx.toggleActiveFinancialManager(...args))),
            ref: "multiSelectToggleManager"
          }, [
            (this.financialManagerNames.length === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, "Choose"))
              : (_openBlock(), _createElementBlock("span", _hoisted_24, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.financialManagerNames, (name, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: name }, [
                      (index > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_25, ", "))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(name), 1)
                    ]))
                  }), 128))
                ])),
            _createVNode(_component_Arrow, {
              class: _normalizeClass({ rotateArrow: this.financialManager })
            }, null, 8, ["class"])
          ], 512),
          _createElementVNode("div", {
            class: _normalizeClass(["create-instrctor__multi-select-wrap", { ActiveMultiSelectFinancialManager: this.financialManager }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.financialManagersData, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id + 10,
                class: "create-instrctor__multi-select"
              }, [
                _createElementVNode("label", {
                  for: item.id + 10,
                  class: "custom-checkbox"
                }, [
                  _createElementVNode("input", {
                    id: item.id + 10,
                    name: item.full_name,
                    onChange: ($event: any) => (
                  _ctx.toggleFinancialManager(item.id, $event, item.full_name)
                ),
                    checked: _ctx.isCheckedFinancialManager(item.id),
                    type: "checkbox"
                  }, null, 40, _hoisted_27),
                  _createElementVNode("span", null, _toDisplayString(item.full_name), 1)
                ], 8, _hoisted_26)
              ]))
            }), 128))
          ], 2),
          (this.errorSchool)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorSchool.financial_manager_ids
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, " Paid subscription for students ", -1)),
          _createElementVNode("div", {
            class: "toggle-switch",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSubscription && _ctx.toggleSubscription(...args)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["toggle-slider", { active: _ctx.isPaidSubscription }])
            }, [
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.isPaidSubscription ? "ON" : "OFF"), 1)
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Free trial duration ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataTrial,
            "select-name": this.trialPlacheholder,
            class: "category__select",
            onOptionSelected: _ctx.handleOptionSelectedTrial,
            title: 'title',
            clearActive: false
          }, null, 8, ["data", "select-name", "onOptionSelected"]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "create-instrctor__not-necessarily" }, "Not necessarily", -1))
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("button", _hoisted_33, [
            (!this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_34, "Create"))
              : _createCommentVNode("", true),
            (this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_35, "Save"))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}

import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import { Form, Field, ErrorMessage } from "vee-validate";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import PhotoMock from "@/assets/svg/mock-super-admin.svg?inline";
import authHeader from "@/services/auth-header";
import Select from "@/components/atoms/CustomSelect.vue";
import CopyInstructor from "@/assets/svg/copy-instructor.svg?inline";
import Arrow from "@/assets/svg/select-arrow.svg?inline";

export default defineComponent({
  name: "CreateSchool",
  data() {
    return {
      imagePreview: null,
      isPreview: false,
      name: "",
      errorSchool: null,
      file: null,
      schoolId: null,
      schoolData: {},
      editTeg: false,
      serverError: null,
      dataAdmins: [],
      adminIds: [],
      financialManagerIds: [],
      financialManagerNames: [],
      generateCode: null,
      errorGenerateCode: null,
      copied: false,
      dataTrial: [
        {
          title: "0",
          value: "0",
        },
        {
          title: "7",
          value: "7",
        },
        {
          title: "14",
          value: "14",
        },
        {
          title: "30",
          value: "30",
        },
        {
          title: "60",
          value: "60",
        },
      ],
      trialPer: "30",
      trialPlacheholder: "30 days",
      adminPlacholder: "Choose school admin",
      isActive: false,
      financialManager: false,
      adminNames: [],
      isPaidSubscription: true,
    };
  },
  components: {
    ArrowBack,
    Form,
    Field,
    AlertBlock,
    PhotoMock,
    Select,
    CopyInstructor,
    Arrow,
  },
  computed: {
    financialManagersData() {
      return this.dataAdmins.filter((admin) => admin.is_financial_manager);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    this.schoolId = this.$route.query.school;
    if (this.$route.query.edit !== undefined) {
      this.editTeg = this.$route.query.edit;
    }
    if (this.schoolId !== undefined) {
      this.getSchool(this.schoolId);
    }
    this.getAdminsList();
    this.generateInviteCode();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    getSchool(id) {
      this.$store.dispatch("admins/getSchool", id).then(
        (res) => {
          this.schoolData = res.data;
          this.name = this.schoolData.name;
          this.generateCode = this.schoolData;
          this.trialPlacheholder =
            this.schoolData.free_trial_days_interval + " days";
          this.adminPlacholder = this.schoolData.admins[0].full_name;
          this.schoolData.admins.forEach((admin) => {
            this.adminIds.push(admin.id);
          });
          this.schoolData.admins.forEach((admin) => {
            if (admin.is_financial_manager) {
              this.financialManagerIds.push(admin.id);
            }
          });
          this.schoolData.admins.forEach((admin) => {
            this.adminNames.push(admin.full_name);
          });
          this.schoolData.admins.forEach((admin) => {
            if (admin.is_financial_manager) {
              this.financialManagerNames.push(admin.full_name);
            }
          });
          this.isPaidSubscription = this.schoolData.is_subscription_enabled;
        },
        (error) => {
          this.errorSchool = error.response.data.errors;
        }
      );
    },
    getAdminsList(params) {
      params = "?per_page=1000";
      this.$store.dispatch("admins/getAdminsList", params).then(
        (res) => {
          this.dataAdmins = res.data;
        },
        (error) => {
          this.errorAdmins = error.response.data.errors;
        }
      );
    },
    createSchool() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("code", this.generateCode?.code);
      this.isPaidSubscription = this.isPaidSubscription ? 1 : 0;
      formData.append("is_subscription_enabled", this.isPaidSubscription);
      formData.append("free_trial_days_interval", this.trialPer);
      this.adminIds.forEach((id) => {
        formData.append("admin_ids[]", id);
      });
      this.financialManagerIds.forEach((id) => {
        formData.append("financial_manager_ids[]", id);
      });
      if (this.file !== null) {
        formData.append("logo", this.file);
      }
      if (this.editTeg === false) {
        this.axios
          .post(API_URL + "super-admin/schools", formData, {
            headers: authHeader(),
          })
          .then(
            () => {
              this.$router.push("/super-admin/school-list");
            },
            (error) => {
              this.errorSchool = error.response.data.errors;
              this.serverError = error.response.data;
            }
          );
      } else {
        this.axios
          .post(
            API_URL + "super-admin/schools/" + this.schoolId + "/update",
            formData,
            { headers: authHeader() }
          )
          .then(
            () => {
              this.$router.push("/super-admin/school-list");
            },
            (error) => {
              this.errorSchool = error.response.data.errors;
            }
          );
      }
    },
    isChecked(id) {
      return this.adminIds.includes(id);
    },
    isCheckedFinancialManager(id) {
      return this.financialManagerIds.includes(id);
    },
    toggleInstructor(id, event, name) {
      if (event.target.checked) {
        if (!this.adminIds.includes(id)) {
          this.adminIds.push(id);
          this.adminNames.push(name);
        }
      } else {
        const index = this.adminIds.indexOf(id);
        if (index !== -1) {
          this.adminIds.splice(index, 1);
          this.adminNames.splice(index, 1);
        }
      }
    },
    toggleFinancialManager(id, event, name) {
      if (event.target.checked) {
        if (!this.financialManagerIds.includes(id)) {
          this.financialManagerIds.push(id);
          this.financialManagerNames.push(name);
        }
      } else {
        const index = this.financialManagerIds.indexOf(id);
        if (index !== -1) {
          this.financialManagerIds.splice(index, 1);
          this.financialManagerNames.splice(index, 1);
        }
      }
    },
    toggleActiveClass() {
      this.isActive = !this.isActive;
    },
    toggleActiveFinancialManager() {
      this.financialManager = !this.financialManager;
    },
    handleOutsideClick(event) {
      const adminToggle = this.$refs.multiSelectToggleAdmin;
      const managerToggle = this.$refs.multiSelectToggleManager;

      if (adminToggle && !adminToggle.contains(event.target)) {
        this.isActive = false;
      }

      if (managerToggle && !managerToggle.contains(event.target)) {
        this.financialManager = false;
      }
    },
    handleOptionSelected(selectedOptions) {
      selectedOptions.forEach((selectedOption) => {
        if (selectedOption.checked) {
          if (!this.adminIds.includes(selectedOption.id)) {
            this.adminIds.push(selectedOption.id);
          }
        } else {
          const index = this.adminIds.indexOf(selectedOption.id);
          if (index !== -1) {
            this.adminIds.splice(index, 1);
          }
        }
      });
    },
    handleOptionSelectedTrial(selectedOption) {
      this.trialPer = selectedOption.value;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    generateInviteCode() {
      this.$store.dispatch("admins/generateInviteCode").then(
        (res) => {
          this.generateCode = res;
          this.errorSchool = null;
        },
        (error) => {
          this.errorGenerateCode = error.response.data.errors;
        }
      );
    },
    copyText() {
      const textToCopy = this.generateCode.code;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    toggleSubscription() {
      this.isPaidSubscription = !this.isPaidSubscription;
    },
  },
});
